
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Router from 'next/router';
import { useEffect } from 'react';
// Component
import Loader from '@/components/base/Loader';
export default function Custom404() {
    useEffect(() => {
        Router.push('/');
    }, []);
    return <Loader className="absolute left-1/2 top-1/2 mr-3 h-8 w-8 text-icoBlue"/>;
}

    async function __Next_Translate__getStaticProps__195f5acd60d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f5acd60d__ as getStaticProps }
  